const initialState = {
  //initial state
  auth: {
    auth: localStorage.getItem('auth') || '',
    status: true,
  }, //auth is empty
  side_bar: {
    sidebarShow: true,
  },
}

export default initialState
