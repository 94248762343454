import React, { useState, useEffect, Suspense } from 'react'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import './scss/style.scss'
import { Provider as ReduxProvider } from 'react-redux'
import initialState from './redux/reducers/initialState'
import reduxStore from './redux/reduxStore'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import CustomAlerts from './components/CustomAlerts'
const reduxstore = reduxStore(initialState)

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const notifysuccesss = (message) => toast.success(message)
  const notifyerror = (message) => toast.error(message)
  return (
    <ReduxProvider store={reduxstore}>
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route
              exact
              path="/"
              name="Login Page"
              element={<Login notifysuccess={notifysuccesss} notifyerror={notifyerror} />}
            />

            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />

            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
        <CustomAlerts />
      </BrowserRouter>
    </ReduxProvider>
  )
}

export default App
