import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function toggleReducer(state = initialState.side_bar, action) {
  switch (action.type) {
    case types.TOGGLE_SIDE_BAR: //catches the login success type
      return {
        ...state,
        sidebarShow: action.data.sidebarShow,
      }

    default:
      return state
  }
}
