import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function authReducer(state = initialState.auth, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS: //catches the login success type
      return {
        // create new object because objets are reference type
        ...state,
        auth: action.data,
        staus: true,
      }

    case types.LOGOUT: //catches the login success type
      return {
        staus: false,
      }

    default:
      return state
  }
}
