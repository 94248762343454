import { combineReducers } from 'redux'
import auth from '../reducers/authReducer'
import side_bar from '../reducers/toggleReducer'

const rootReducer = combineReducers({
  //combining all reducers and creating a big object
  auth,
  side_bar,
})
export default rootReducer
